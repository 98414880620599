"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Password Manager
 * This is a password manager server.
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: test@te.st
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
function LogonInformationFromJSON(json) {
    return LogonInformationFromJSONTyped(json, false);
}
exports.LogonInformationFromJSON = LogonInformationFromJSON;
function LogonInformationFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'username': json['username'],
        'password': json['password'],
    };
}
exports.LogonInformationFromJSONTyped = LogonInformationFromJSONTyped;
function LogonInformationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'username': value.username,
        'password': value.password,
    };
}
exports.LogonInformationToJSON = LogonInformationToJSON;
