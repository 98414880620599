"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Password Manager
 * This is a password manager server.
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: test@te.st
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
function AccountIdFromJSON(json) {
    return AccountIdFromJSONTyped(json, false);
}
exports.AccountIdFromJSON = AccountIdFromJSON;
function AccountIdFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'index': json['index'],
        'name': json['name'],
        'additional': json['additional'],
        'password': json['password'],
    };
}
exports.AccountIdFromJSONTyped = AccountIdFromJSONTyped;
function AccountIdToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'index': value.index,
        'name': value.name,
        'additional': value.additional,
        'password': value.password,
    };
}
exports.AccountIdToJSON = AccountIdToJSON;
