"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Password Manager
 * This is a password manager server.
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: test@te.st
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
var runtime_1 = require("../runtime");
function UserWebAuthnCredFromJSON(json) {
    return UserWebAuthnCredFromJSONTyped(json, false);
}
exports.UserWebAuthnCredFromJSON = UserWebAuthnCredFromJSON;
function UserWebAuthnCredFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': json['name'],
        'id': json['id'],
        'lastUsed': !runtime_1.exists(json, 'lastUsed') ? undefined : (new Date(json['lastUsed'])),
    };
}
exports.UserWebAuthnCredFromJSONTyped = UserWebAuthnCredFromJSONTyped;
function UserWebAuthnCredToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'id': value.id,
        'lastUsed': value.lastUsed === undefined ? undefined : (value.lastUsed.toISOString()),
    };
}
exports.UserWebAuthnCredToJSON = UserWebAuthnCredToJSON;
