"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Password Manager
 * This is a password manager server.
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: test@te.st
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
var runtime_1 = require("../runtime");
function UserInformationFromJSON(json) {
    return UserInformationFromJSONTyped(json, false);
}
exports.UserInformationFromJSON = UserInformationFromJSON;
function UserInformationFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'email': !runtime_1.exists(json, 'email') ? undefined : json['email'],
    };
}
exports.UserInformationFromJSONTyped = UserInformationFromJSONTyped;
function UserInformationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'email': value.email,
    };
}
exports.UserInformationToJSON = UserInformationToJSON;
