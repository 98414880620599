"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Password Manager
 * This is a password manager server.
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: test@te.st
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
var runtime_1 = require("../runtime");
function DecryptionKeyFromJSON(json) {
    return DecryptionKeyFromJSONTyped(json, false);
}
exports.DecryptionKeyFromJSON = DecryptionKeyFromJSON;
function DecryptionKeyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'decryptionKey': !runtime_1.exists(json, 'decryptionKey') ? undefined : json['decryptionKey'],
    };
}
exports.DecryptionKeyFromJSONTyped = DecryptionKeyFromJSONTyped;
function DecryptionKeyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'decryptionKey': value.decryptionKey,
    };
}
exports.DecryptionKeyToJSON = DecryptionKeyToJSON;
