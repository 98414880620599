"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Password Manager
 * This is a password manager server.
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: test@te.st
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
/**
* @export
* @enum {string}
*/
var HistoryItemEventEnum;
(function (HistoryItemEventEnum) {
    HistoryItemEventEnum["Login"] = "Login";
    HistoryItemEventEnum["ChangePassword"] = "ChangePassword";
    HistoryItemEventEnum["Registration"] = "Registration";
})(HistoryItemEventEnum = exports.HistoryItemEventEnum || (exports.HistoryItemEventEnum = {}));
function HistoryItemFromJSON(json) {
    return HistoryItemFromJSONTyped(json, false);
}
exports.HistoryItemFromJSON = HistoryItemFromJSON;
function HistoryItemFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'userAgent': json['UserAgent'],
        'iP': json['IP'],
        'time': (new Date(json['Time'])),
        'event': json['Event'],
        'eventResult': json['EventResult'],
    };
}
exports.HistoryItemFromJSONTyped = HistoryItemFromJSONTyped;
function HistoryItemToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'UserAgent': value.userAgent,
        'IP': value.iP,
        'Time': (value.time.toISOString()),
        'Event': value.event,
        'EventResult': value.eventResult,
    };
}
exports.HistoryItemToJSON = HistoryItemToJSON;
