"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Password Manager
 * This is a password manager server.
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: test@te.st
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
function UserWebAuthnCreateResponseFromJSON(json) {
    return UserWebAuthnCreateResponseFromJSONTyped(json, false);
}
exports.UserWebAuthnCreateResponseFromJSON = UserWebAuthnCreateResponseFromJSON;
function UserWebAuthnCreateResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'attestationObject': json['attestationObject'],
        'clientDataJSON': json['clientDataJSON'],
        'type': json['type'],
    };
}
exports.UserWebAuthnCreateResponseFromJSONTyped = UserWebAuthnCreateResponseFromJSONTyped;
function UserWebAuthnCreateResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'attestationObject': value.attestationObject,
        'clientDataJSON': value.clientDataJSON,
        'type': value.type,
    };
}
exports.UserWebAuthnCreateResponseToJSON = UserWebAuthnCreateResponseToJSON;
