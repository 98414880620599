"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Password Manager
 * This is a password manager server.
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: test@te.st
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
function UserSettingsFromJSON(json) {
    return UserSettingsFromJSONTyped(json, false);
}
exports.UserSettingsFromJSON = UserSettingsFromJSON;
function UserSettingsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'encryptedUserSettings': json['encryptedUserSettings'],
    };
}
exports.UserSettingsFromJSONTyped = UserSettingsFromJSONTyped;
function UserSettingsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'encryptedUserSettings': value.encryptedUserSettings,
    };
}
exports.UserSettingsToJSON = UserSettingsToJSON;
